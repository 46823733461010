import { Flex } from "@chakra-ui/layout";
import { useAppSelector } from "@/lib/hooks";
import { Loader } from "@/utils/Loader";

type Props = {
  children: React.ReactNode;
};

export default function SignInLayout({ children }: Props) {
  const accessToken = useAppSelector((state) => state.user.accessToken);

  if (accessToken !== "") {
    return (
      <Flex
        mt={0}
        bg="white"
        justifyContent="center"
        alignItems="center"
        minH={"100vh"}
        maxW={"100%"}
        h={"100vh"}
      >
        <Loader />
      </Flex>
    );
  }

  return <>
    <Flex
      mt={0}
      bg="white"
      justifyContent="center"
      alignItems="flex-start"
      minH={"100vh"}
      maxW={"100%"}
    >
      {children}
    </Flex>
  </>
}

