import { Loader } from "@/utils/Loader";
import Head from "next/head";
import { Flex } from "@chakra-ui/react";
import { ReactElement } from "react";
import SignInLayout from "@/components/Layouts/SignInLayout";
import { NextPageWithLayout } from "@/lib/types";

const Index: NextPageWithLayout = () => {
  return (
    <>
      <Head>Loading...</Head>
      <Flex justifyContent="center" alignItems="center" h="100vh">
        {/* INDEX PAGE */}
        <Loader />
      </Flex>
    </>
  );
};

Index.getLayout = function getLayout(page: ReactElement) {
  return <SignInLayout>{page}</SignInLayout>;
};

export default Index;
